<template>
  <div class="container">
    <header class="jumbotron">
      <!-- <h3>{{ content }}</h3> -->
      <!-- <h3>Dobrodosli u Ifero 2.0</h3> -->
    </header>
  </div>
</template>

<script>
// import UserService from "../../services/user.service";

export default {
  name: "Home",
  data() {
    return {
      content: "",
    };
  },
  // mounted() {
  //   UserService.getPublicContent().then(
  //     (response) => {
  //       this.content = response.data;
  //     },
  //     (error) => {
  //       this.content =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();
  //     }
  //   );
  // },
};
</script>
