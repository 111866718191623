<template>
  <div id="app">
    <nav
      v-if="
        this.$router.currentRoute.value.name != 'report' &&
        this.$router.currentRoute.value.name != 'report_id' &&
        this.$router.currentRoute.value.name != 'invoicePrint'
      "
      class="navbar navbar-expand navbar-dark"
      style="background-color: #090"
    >
      <div class="container-fluid">
        <div v-if="currentUser" class="navbar-nav ml-auto">
          <a href="/" class="navbar-brand">
            <img
              src="/images/logo.png"
              alt="Ifero 2.0"
              class="d-inline-block align-text-top"
            />
          </a>
          <div class="navbar-nav mr-auto" style="align-items: center">
            <li class="nav-item">
              <router-link to="/customers" class="nav-link">
                <i class="bi bi-person-lines-fill"></i>
                Kupci
              </router-link>
            </li>
            <div class="dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDarkDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-file-earmark-text"></i>
                Dokumenta
              </a>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <router-link to="/invoices/Proform" class="dropdown-item"
                    >Predračun</router-link
                  >
                </li>
                <li>
                  <router-link to="/invoices/Advance" class="dropdown-item"
                    >Avansni račun</router-link
                  >
                </li>
                <li>
                  <router-link to="/invoices/Normal" class="dropdown-item"
                    >Račun</router-link
                  >
                </li>
                <li>
                  <router-link to="/invoices/Export" class="dropdown-item"
                    >Export</router-link
                  >
                </li>
              </ul>
            </div>
            <li class="nav-item">
              <router-link
                v-if="currentUser"
                to="/reservation"
                class="nav-link"
              >
                <i class="bi bi-credit-card-2-back"></i>
                Outbound</router-link
              >
            </li>
            <li class="nav-item" v-if="currentUser.stations.length == 0 || currentUser.stations.some(s => s.code == 'CESIJE')">
              <router-link v-if="currentUser" to="/cessions" class="nav-link">
                <i class="bi bi-credit-card"></i>
                Cesije</router-link
              >
            </li>
            <li class="nav-item" v-if="currentUser.stations.length == 0 || currentUser.stations.some(s => s.code == 'PRODAJA')">
              <router-link v-if="currentUser" to="/sellings" class="nav-link">
                <i class="bi bi-credit-card-2-front"></i>
                Prodaja vozila</router-link
              >
            </li>
          </div>
        </div>

        <div v-if="!currentUser" class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link to="/register" class="nav-link">
              <font-awesome-icon icon="user-plus" /> Sign Up
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              <font-awesome-icon icon="sign-in-alt" /> Login
            </router-link>
          </li>
        </div>

        <div v-if="currentUser" class="navbar-nav ml-auto">
          <div class="dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDarkDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <font-awesome-icon icon="user" />
              {{ currentUser.username }}
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton1"
            >
              <li v-if="showAdminBoard">
                <router-link to="/tax-rates" class="dropdown-item"
                  >Poreske stope</router-link
                >
              </li>
              <li v-if="showAdminBoard">
                <router-link to="/articles" class="dropdown-item"
                  >Artikli</router-link
                >
              </li>
              <li v-if="showAdminBoard">
                <router-link to="/units" class="dropdown-item"
                  >Jedinice mere</router-link
                >
              </li>
              <li v-if="showAdminBoard">
                <router-link to="/bank" class="dropdown-item"
                  >Banke</router-link
                >
              </li>
              <li v-if="showAdminBoard">
                <router-link to="/stations" class="dropdown-item"
                  >Poslovnice</router-link
                >
              </li>
              <li>
                <router-link to="/exchange-rates" class="dropdown-item"
                  >Kursne liste</router-link
                >
              </li>
              <li v-if="showAdminBoard">
                <router-link to="/customer-types" class="dropdown-item"
                  >Tip Kupca</router-link
                >
              </li>
              <li v-if="showAdminBoard">
                <router-link to="/countries" class="dropdown-item"
                  >Države</router-link
                >
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li v-if="showAdminBoard">
                <router-link to="/users" class="dropdown-item">
                  <i class="bi bi-people"></i>
                  Korisnici
                </router-link>
              </li>
              <li v-if="showAdminBoard">
                <router-link to="/profile" class="dropdown-item">
                  <i class="bi bi-person"></i>
                  {{ currentUser.username }}
                </router-link>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item" @click.prevent="openHelp">
                  <i class="bi bi-question-circle"></i> Uputstvo
                </a>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item" @click.prevent="logOut">
                  <font-awesome-icon icon="sign-out-alt" /> LogOut
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <div class="container-fluid mx-auto mt-3 p-3">
      <!-- <router-view /> -->
      <router-view v-slot="{ Component }">
        <transition name="slide-fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
  <transition name="custom-classes-transition">
    <modal v-if="showModal" @close="closeModal()"></modal>
  </transition>
  <alert-message v-if="showAlert" />
</template>

<script>
import Modal from "./components/widgets/Modal.vue";
import AlertMessage from "./components/widgets/AlertMessage.vue";

export default {
  components: {
    Modal,
    AlertMessage,
  },
  computed: {
    showModal() {
      return this.$store.state.app.status.showModal;
    },
    showAlert() {
      return this.$store.state.app.alert;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_MODERATOR");
      }

      return false;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    openHelp() {
      window.open("https://docs.google.com/document/d/1pBDNozrD4Cj85S8dTEfrjmubT21bFAe0SjbihO_Ro8g/edit?usp=sharing", "_blank");
    },
    closeModal() {
      this.$store.commit("app/hideModal");
    },
  },
  mounted() {
    // console.log(process.env.VUE_APP_BE_URL);
    // console.log(this.currentUser);
    // console.log(this.$store.state.auth.status.loggedIn);
    //TODO: check if user loged in
    //this.$store.app.fetcTaxRates();
    if (this.$store.state.auth.status.loggedIn == true) {
      this.$store.dispatch("app/fetcTaxRates");
      this.$store.dispatch("app/fetcArticles");
    }
  },
};
</script>

<style scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.5s;
  /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
}

/* .slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
} */

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
}
</style>
