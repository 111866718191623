import axios from "axios";
import router from "../router";
import store from "../store";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BE_URL + "api/",
  withCredentials: true,
  headers: {
    "Content-type": "application/json"
  },
});


instance.interceptors.response.use(function (response) {
  // console.log({ response })
  return response
}, function (error) {
  if (error.response.status == 401 || error.response.status == 403) {
    store.dispatch("auth/logout");
    router.push('/login')
  }
  return Promise.reject(error)
})

export default instance;