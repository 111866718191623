<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" @click.stop="">
        <div class="card">
          <div
            v-if="currentProperties && currentProperties.title"
            class="card-header d-flex justify-content-between"
          >
            {{ currentProperties.title }}
            <button
              v-if="!currentProperties.noClose"
              type="button"
              class="btn-close text-reset"
              aria-label="Close"
              @click="closeModal"
            ></button>
          </div>
          <div class="card-body scr">
            <component
              :is="modalCommponent"
              v-bind="currentProperties"
            ></component>
          </div>
          <div
            v-if="currentProperties && currentProperties.foother"
            class="card-footer text-muted"
          >
            <span v-if="currentProperties.foother.isComponent">
              <component :is="currentProperties.foother.commponent"></component>
            </span>
            <span v-else>
              {{ currentProperties.foother.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: ["data"],
  data() {},
  computed: {
    modalCommponent() {
      return this.$store.state.app.modal;
    },
    currentProperties() {
      return this.$store.state.app.modalParams;
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("app/hideModal");
    },
  },
};
</script>


<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  min-width: 300px;
  max-width: 950px;
  margin: 0px auto;
  /* padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

/* .modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
} */

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* .scr {
  max-height: calc(100vh - 100px);
  overflow: auto;
} */
</style>