import http from "../utils/http-common";

class ArticlesDataService {
  getAll() {
    return http.get("/articles");
  }
  getAllForList() {
    return http.get("/articles/ext/forDoc?limit=100000&ascending=1");
  }

  get(id) {
    return http.get(`/articles/${id}`);
  }

  getDefault(type) {
    return http.get(`/articles/dflt/${type}`);
  }

  create(data) {
    return http.post("/articles", data);
  }

  update(id, data) {
    return http.put(`/articles/${id}`, data);
  }

  delete(id) {
    return http.delete(`/articles/${id}`);
  }
}

export default new ArticlesDataService();